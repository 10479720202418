// Here you can add other styles
.body {
  font-family: "Roboto", sans-serif !important;
  line-height: 0.25 !important;
}

.app {
  position: relative;
}

.loader {
  position: absolute;
  margin: 0 auto;
  height: 100%;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
  z-index: 10000;
  width: 100%;
  opacity: 0.8;
  padding-left: calc(30%);
}

.loader .logo-loader {
  width: 70px;
  margin-left: 10px;
}

.loader .loader-img {
  width: 90px;
  height: auto;
  margin-top: -15px;
}

.alert-toast {
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 99999;
}

.popup-alert {
  position: fixed;
  top: 0px;
  width: 100%;
  padding-top: 40%;
  height: 100%;
  z-index: 99999;
  background-color: #f0f3f5 !important;
  color: #01071a !important;
  font-size: 22px !important;
  text-align: center !important;
  font-weight: 500 !important;
  vertical-align: middle !important;
  opacity: 40;
}

.container-loading {
  position: fixed;
  top: 0;
  left: 0;
}

.position-relative {
  position: relative;
}

.btn-success,
.btn-info,
.btn-warning,
.btn-delete {
  color: #fff !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff !important;
}

.width-50-percent {
  width: 50%;
}

.pagination-bottom {
  margin-top: 10px;
}

.top-row-20 {
  margin-top: 20px;
}

.top-row-30 {
  margin-top: 30px;
}

.password-recover-text {
  margin-top: 10px;
  font-style: italic;
  color: #8c8c8c;
}

.user-recover {
  margin: 10px 0;
}

.card {
    margin-bottom: 0px !important;
}
.card-body {
  padding-top: 30px;
}

.card-footer {
  padding: 0px 20px 0px 0px;
}

.card-fieldset {
  // padding: 20px;
  // border: 1px solid #cad0d5;
  // border-radius: 10px;
  // background: #f8fcff;
}

.card-legend {
  width: auto;
  padding: 0 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.react-datepicker-wrapper input {
  border-radius: 5px;
  padding: 5px 15px;
  border: 1px solid #e6e8eb;
}

.input-80-inline {
  width: 80%;
  display: inline-block;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.btn.btn-default {
  border: 1px solid #c4c4c4;
}

.picture-scroll {
  width: 100%;
  height: 500px;
  overflow: scroll;
}

input,
select {
  color: #000 !important;
}

.loading-image {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.loading-image img {
  width: 100%;
  height: 100%;
}

.btn-groups .btn {
  margin-right: 10px;
  cursor: pointer;
}

.logo {
  width: 140px;
  height: 190px;
  margin-top: -50px;
  margin-bottom: 20px;
  display: inline-block;
  border: 2px solid;
}

img.login-logo {
  width: 300px !important;
  margin-bottom: 20px;
}

.logo-voucher {
  width: 200px !important;
  height: auto;
  margin-bottom: 20px;
}

/* Fixed header table */
.fixed-header-table {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.adr-table {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}

.adr-thead {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.adr-tr {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.adr-thead .adr-th,
.adr-thead .adr-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}

.adr-thead .adr-tr {
  overflow-y: scroll;
}

.adr-th-border-bottom {
  border-bottom: 3px solid #f2f2f2;
}

.adr-body {
  -webkit-box-flex: 99999;
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
}

.adr-th,
.adr-td {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-property: width, min-width, padding, opacity;
  transition-property: width, min-width, padding, opacity;
}

.adr-body .adr-td {
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}
.adr-body .adr-td:last-child {
  border-right: 0;
}

.adr-resizable-header-content {
  text-align: center;
  font-weight: bold;
  //overflow: hidden;
  //text-overflow: ellipsis;
}
.adr-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}
.adr-tr-group {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
/* End fixed header table */

.action-buttons button {
  display: inline;
  margin-left: 10px;
}

.action-buttons button:first-child {
  margin-left: 0;
}

.action-buttons button:last-child {
  margin-bottom: 0;
}

.input-group-prepend .fa {
  width: 40px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #e4e7ea;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #8189a3;
  color: #757575;
}

.input-group-prepend .input-group-text i {
  display: block;
}

.group-right {
  display: inline !important;
  float: right !important;
}

.language-icon-box {
  height: 35px;
  width: 40px;
}

.language-icon-box .input-group-text {
  padding-left: 9px;
  padding-top: 15px;
}

.loading-image {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: #f0f3f4;
}

.loading-image img {
  width: 600px !important;
  height: 340px !important;
  left: 28%;
  top: 15%;
  position: absolute;
}

.hidden {
  display: none !important;
}

.group-top-dt-buttons {
  padding-bottom: 10px;
  margin-top: -15px;
}

.group-top-dt-buttons button {
  margin-right: 10px;
}

.tables-checkbox {
  position: relative;
  width: 25px;
}
.tables-checkbox input {
  margin:0;
  margin-top: -5px;
}

.form-check-input {
  display: inline-block;
}

.table-responsive .table {
  border-left: 1px solid #c8ced3;
  border-right: 1px solid #c8ced3;
}

.table-responsive .table tr {
  border-bottom: 1px solid #c8ced3;
}

.sidebar .nav-link {
  border: 0;
  background: #04695F;
  width: 100%;
  text-align: left;
  border-radius: 0;
  color: #fff !important;
  border-bottom: 1px solid #acb5bc7a !important;
  cursor: pointer;
}

.sidebar .nav-dropdown.open {
  background: #979ea5 !important;
}

.sidebar .nav-link i {
  color: #fff !important;
}

.sidebar .nav-link.active {
  color: #fff;
  background: #53b2d2;
}

.sidebar .nav-link.active .nav-icon {
  color: #fff;
}

.sidebar .nav-dropdown-items .nav-item {
  padding: 0;
  list-style: none;
  margin-left: 15px;
}
.sidebar .nav-item.selected .nav-link {
  background: #0F900D;
}

.sidebar .nav-link :hover {
  background: #0F900D;
}

.language-flag {
  height: 20px;
  margin-top: -10px;
}

.register-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.action-buttons-bottom-box {
  margin-bottom: 0;
}

.card-fieldset .form-group label {
  margin-top: 5px;
  line-height: 1.5 !important;
}

.card-body {
  flex: 1 1 auto;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.app-header {
  background-color: #04695F !important;
}

.app-body {}

.table tbody th a {
  font-weight: 400;
  color: #010811 !important;
}

/*----------------------  Change style of layout  ---------------------*/
.navbar-nav .nav-link {
  color: #000000 !important;
}

.admin-left {
  display: block !important;
  float: left;
}

.admin-right {
  display: block !important;
  float: right;
}

.admin-margin-left {
  margin-left: 10px;
}

.admin-form-tittle {
  display: block !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.admin-footer-paging {
  margin-right: 20px;
  display: block;
  float: right;
}

.table th,
.table td {
  vertical-align: middle !important;
}

.badge {
  display: inline-block;
  padding: 0.3em 0.3em !important;
  font-size: 100% !important;
  margin-right: 5px;
}

.admin-bg-dark {
  background: #91c5d4;
}

.admin-border-bottom {
  border-bottom: 1px solide #e4e7ea;
}

.admin-margin-left {
  margin-left: 5px;
}

.action-buttons button:last-child {
  margin-bottom: 0;
}

.action-buttons button.btn-info {
  color: #fff !important;
}

.header-icon {
  margin-top: 5px !important;
}

.input-group-prepend .fa {
  width: 40px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #e4e7ea;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #f0f3f5;
  color: #757575;
}

.loading-image {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: #f0f3f4;
}

.loading-image img {
  width: 600px !important;
  height: 340px !important;
  left: 28%;
  top: 15%;
  position: absolute;
}

.hidden {
  display: none !important;
}

.action-inline-block {
  display: inline-block;
  margin-left: 5px;
}

.tooltip-inner {
  background-color: #efb103;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #efb103;
}

.react-datepicker-wrapper input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.card-header .input-group {
  display: inherit !important;
}

.card-header {
  padding: 0.5rem 1.25rem !important;
  text-align: left;
}

.search-box .card-header {
  display: none !important;
}

.search-box .card-footer {
  padding: 0px !important;
}

.search-box .card {
  background-color: #f0f3f5;
}

.search-box .col-md-6 {
  background: #f0f3f5;
}

.form-group.row span input {
  border-radius: 3px !important;
  border: 1px solid #e4e5e6 !important;
  padding: 5px 10px !important;
  width: 100% !important;
}

.search-box .card-fieldset {
  padding: 0px;
  border: 0px;
  border-radius: 0px;
  background: #f0f3f5 !important;
}

.form-control-static {
  text-align: left;
  border-bottom: 1px solid #ebf0f3;
  min-height: 20px !important;
  font-size: medium;
}

.react-datepicker__time-container {
  width: 120px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 120px !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 130px !important;
}

@media screen and (max-width: 600px) {
  .form-group label {
    float: left !important;
  }

  ol.breadcrumb {
    display: none !important;
  }
}

.form-group label {
  font-weight: none;
}

@media (min-width: 768px) {
  .col-md-special-2 {
    max-width: 12.5%;
  }

  .col-md-special-10 {
    max-width: 87.5%;
  }
}

.navbar-nav .btn-group {
  margin-left: 4px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-radius: 2px !important;
  color: #fff;
  border: 1px solid #ccc;
  background: none !important;
}

ol.breadcrumb {
  background: none !important;
  border: none;
  vertical-align: middle !important;
  top: 10px !important;
}

.dropdown-menu {
  left: -25px !important;
}

.dropdown-item:hover {
  background-color: none !important;
}

.dropdown-item:first-child {
  background-color: none !important;
}

.dropdown-item:first-child:hover {
  background-color: #ccc !important;
}

.full-lines .row > div:first-child {
  width: 12.5%;
  max-width: unset;
  flex: unset;
}

.full-lines .row > div:last-child {
  width: 100%;
  max-width: unset;
  flex: unset;
}

.album-save-box > .row > div,
.album-save-box > .row > div .full-lines {
  width: 100% !important;
}

.album-save-box .full-lines .row > div:first-child {
  width: 12.5%;
}

.album-save-box .full-lines .row > div:last-child {
  width: 100%;
}

.files {
  position: fixed;
  top: 10%;
  left: 18%;
  width: 80%;
  height: 80%;
  background: #fff;
  padding: 20px;
  z-index: 1;
  border: 1px solid #20a8d8;
}

.files .files-dropzone {
  border: 1px solid #ff5722;
  padding: 10px;
  height: 60%;
  width: 300px;
  position: absolute;
  right: 20px;
  top: 85px;
  padding-top: 15%;
}

.file-avatar {
  width: auto;
  height: 30px;
  text-align: center;
  line-height: 20px;
  margin-top: 5px;
  margin-right: 15px;
  border: 1px solid #ccc;
  background: #eff1f7;
  display: inline-block;
  position: relative;
  float: left;
  cursor: pointer;
}

.file-avatar .file-name {
  position: revert;
  top: 30px;
  float: right;
  font-size: 12px;
  color: #2a3b59;
  padding-left: 10px;
}

.albums-medias-box {
  position: relative;
}

.header-medias {
  padding-bottom: 10px;
  border-bottom: 1px solid #274e75;
}

.close-medias {
  position: absolute;
  top: -10px;
  right: -10px;
  color: #fff;
  background: #53b2d2;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
}

.files-box {
  width: 67%;
}

/* Modal of vinsen */
.vs-box {
  position: fixed;
  top: 5%;
  left: 5%;
  border: 1px solid #20a8d8;
  padding: 10px;
  background: #fff;
  color: #274e75;
  width: 90%;
  z-index: 10021;
  text-align: left;
}

.vs-box .vs-box-body button {
  margin-left: 10px;
}

.confirm-modal .vs-box .col-md-3 {
  width: 100% !important;
  text-align: left !important;
  max-width: 100% !important;
  flex: none !important;
}

.confirm-modal .vs-box .col-md-9 {
  display: none !important;
}

.vs-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #101113;
  z-index: 10020;
  opacity: 0.7;
}

.vs-close {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid #6d0606;
  color: #6d0606;
  padding-left: 4px;
  line-height: 18px;
}

.vs-box-header {
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #20a8d8;
}

.confirm-modal {
  position: relative;
}

.confirm-modal .card-header {
  display: none;
}

.confirm-modal .card {
  margin-bottom: 0;
}

.confirm-modal label span {
  display: none;
}

.media-files .file-avatar.file-image img {
  height: 20px;
}

.media-files .file-avatar .file-name {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.files-dropzone {
  background-color: #1593b8;
  width: 100px;
  height: 30px;
  margin-top: 2px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  padding-top: 5px;
  text-align: center;
  cursor: pointer;
  float: left;
}

.font-library {
  font-size: 80px;
  color: burlywood;
  line-height: 100px;
  cursor: pointer;
}

.album-name {
  width: 200px;
  text-align: left;
}

.file-avatar.file-image img {
  width: 20px;
}

.title-header {
  color: #ffffff;
  display: contents !important;
}

.dashboard-search-box {
  width: 100%;
}

.profile-box {
  position: absolute;
  background: #64a4e3;
  color: #fff;
  top: 40px;
  right: 10px;
  border: 1px solid;
  width: 150px;
}

.profile-box :hover {
  background: #274e75;
}

.profile-box .pb-item {
  border-bottom: 1px solid;
  padding: 10px;
}

.form-group.row span input.error-message,
.form-group.row span input.error-message:focus,
.error-message,
.error-message:focus,
.taginputs-error-message > div,
.taginputs-error-message:focus > div {
  border: 1px solid #ff5722 !important;
  box-shadow: none;
  outline: none;
}

.error-message::placeholder {
  color: #ff5722 !important;
}

.text-error-message {
  color: #ff5722 !important;
}

.c-textarea {
  min-width: 350px;
}

.c-button {
  margin-bottom: 10px;
  margin-left: 10px;
}

.comment-body {
  text-align: left;
  width: 100%;
  background-color: #f0f3f5;
  margin-bottom: 5px;
  padding: 3px;
  border-radius: 5px;
  vertical-align: top;
}

.upload-error {
  color: #f00;
}

.dropdown-notify {
  top: 50px;
  left: -100px;
  width: 300px;
}

.dropdown-notify-header,
.dropdown-notify-footer {
  background-color: #f0f3f5;
}

.product-detail {
  position: relative;
}

.product-detail-left .card-body {
  width: 50%;
}

.product-image {
  width: auto;
  height: 200px;
  position: absolute;
  display: block;
}

.product-image img {
  height: 400px;
}

.c-img-avatar,
.avatar > img,
.img-circle {
  max-width: 80%;
  height: auto;
  border-radius: 50em;
  margin-right: 5px;
}

.c-avatar-img {
  width: 40px;
  margin-right: 10px;
}

.comment-content {
  display: inline;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid;
  background-color: #f0f3f5;
  border-color: #d8dbe0;
}

.dashboard-data-box {
  border: 1px solid #73818f;
  border-radius: 3px;
  min-width: 175px;
  margin: 5px;
  padding: 5px;
}

.empty_row {
  width: 100%;
  height: 20px;
}

.display-inline {
  display: inline-block;
}

.n-scroll {
  overflow-y: scroll;
  height: 500px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border: none !important;
}

b {
  font-weight: 500;
}

.d-contents {
  display: contents;
}

.col-txt-left {
  text-align: left !important;
  width: 50%;
  border-bottom: 1px solid #ebf0f3;
  min-height: 20px !important;
  font-size: medium;
  padding-left: 15px;
  line-height: 25px;
  margin-bottom: 0px;
}

.col-txt-right {
  text-align: right !important;
  width: 50%;
  border-bottom: 1px solid #ebf0f3;
  min-height: 20px !important;
  font-size: medium;
  padding-right: 15px;
  line-height: 25px;
  margin-bottom: 0px;
}

.padding-r-30 {
  padding-right: 30px;
}

.padding-l-30 {
  padding-left: 30px;
}

.two-panel .table-responsive {
  width: calc(50% - 10px);
  display: inline-block;
}

.two-panel .table-responsive + div {
  width: calc(50% - 3px);
  float: right;
}

.dtc-action:hover i {
  display: initial;
  padding-left: 10px;
}

.dtc-action i {
  display: none;
}

.ellipsis-title {
  white-space: nowrap;
  width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0d9ce9;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
}
.no-border {
  border: 0 !important;
}

.media-close {
  position: absolute;
  right: 5px;
  top: 2px;
  font-size: 20px;
  color: #f00;
  cursor: pointer;
}

.customer-registration .card-fieldset {
  padding: 0px !important;
  /* border: 1px solid #cad0d5; */
  border-radius: 0px !important;
  border: 0px !important;
  background: #fff !important;
  line-height: 0px !important;
}
.customer-registration .card-header {
  display: none !important;
}

.customer-registration .card, .card {
  border: none !important;
  border-radius: 0px !important;
}

.text-center {
  line-height: 1.5;
}

.text-left {
  float: left !important;
  text-align: left !important;
}

.fWeight-500 {
  font-weight: 500;
}

.customer-registration .row {
  display: unset !important; 
  line-height: 6px !important;
}
.customer-registration .col-lg-12 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.customer-registration .card-footer {
  background-color: #fff !important;
  border-top: 0px !important;
  padding: 0px !important;
}
#root .customer-registration {
  background: #fff;
  border: 1px solid #ccc;
}

.role-description {
  color: #d88213;
}
.ad-checkbox {
  position: relative;
  margin-top: 0;
  margin-left: 0;
}

.tags {
  text-align: center;
  color: #FFF;
  width: 50px;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #64a4e3;
}

.form-custom {
  margin: 0;
  padding: 0;
}

.form-img {
  text-align: center;
}

.form-img img {
  width: 200px;
  height: auto;
  border-radius: 10px;
  border: 1px solid #f0f3f5;
}

.form-img-logo img {
  width: 200px;
  height: auto;
}


.form-img p {
    font-weight: 500;
    margin-bottom: 0px !important;
    padding-top: 5px;
}

.form-text {
  margin: 0;
  padding: 0;
  font-weight: 400;
  border: none;
}

.form-text dd {
  border-bottom: 1px solid #ebf0f3;
  float: left;
}

.form-text dd p {
  margin-bottom: 4px !important;
}

h4.card-title.mb-0, .card-header h4 {
  font-size: 14px !important;
  font-weight: 500;
  vertical-align: middle;
  color: #01081d;
}

.action-buttons-table {
  position: relative;
}

.action-buttons-absolute {
  position: fixed;
  z-index: 999999;
  background: #fff;
  border: 1px solid #9c9c9c;
  border-radius: 5px;
  margin-top: 5px;
  padding-right: 5px;
  padding-top: 5px;
}

.action-buttons-absolute .action-inline-block {
  display: block;
  margin-bottom: 5px;
}

.action-buttons-absolute .action-inline-block button {
  width: 100%;
}

.form-header {
  padding: 0px !important;
  border: none !important;
  line-height: 10px;
  font-weight: 400;
}

.form-body {
  padding: 0px !important;
  border: none !important;
  line-height: 16px;
  font-weight: 400;
}

.signature-height {
  padding-top: 100px;
}

.signature-i {
  font-weight: 400;
}

.fHeight-30 {
  padding-top: 30px;
}

.renderCheckItems {
  float: right;
}

.table-scroll .card-body {
  height: calc(100vh - 300px);
  overflow-y: scroll;
}

.vsboxRenderTotal {
  display: flow-root;
}

.text-transform-up {
  text-transform: uppercase;
}

.folder-icon {
  padding-right: 5px;
  font-size:15px;
  color:#dab904;
}
.file-icon {
  padding-right: 5px;
  font-size:15px;
  color:#dd4a10;
}

.vs-box.set_scroll {
  height: 650px !important;
  overflow-y: scroll;
}

.blue-color {
  color:#10bc3b;
}

.text-link {
  color: #026cea !important;
  cursor: pointer;
}